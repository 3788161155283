import React, {useEffect, useMemo, useRef, useState} from 'react';
import generalStyles from '../../styles/general.module.css';
import * as Button from '../../components/UI/Forms/Button';
import Icon from '../../components/UI/General/Icon';
import Text from '../../components/UI/Typography/Text';
import Box from '../../components/UI/General/Box';
import { Controller, useFieldArray, useForm, useWatch } from 'react-hook-form';
import Input from '../../components/UI/Forms/Input';
import Label from '../../components/UI/Forms/Label';
import DatePicker from 'react-datepicker';
import Item from '../../components/Admins/Requisition/Item';
import styles from '../../styles/requisition.module.css';
import Textarea from '../../components/UI/Forms/Textarea';
import Documents from '../../components/UI/Forms/Documents';
import moment from 'moment';
import spmsServiceService from '../../services/spmsService.service';
import TabbedNav from '../../components/UI/General/TabbedNav';
import { useStore } from '../../store/store';
import Toast from '../../components/UI/General/Toast';
import { useNavigate, useParams } from 'react-router-dom';
import HistoryTab from '../../components/Admins/PurchaseOrders/Tabs/HistoryTab';
import NotesTab from '../../components/Admins/PurchaseOrders/Tabs/NotesTab';
import Summary from '../../components/Admins/PurchaseOrders/View/Summary';
import QuotesTab from '../../components/Admins/PurchaseOrders/Tabs/QuotesTab';
import getExchangeRate from '../../services/getExchangeRate';
import {regExps} from "../../utils/regExps";
import {useSubscriptionAllowed} from "../../hooks/useSubscriptionAllowed";
import UpgradePlan from "../../components/shared/UpgradePlan";
import SelectUsers from "../../components/shared/SelectUsers";
import SelectBudgets from "../../components/shared/SelectBudgets";
import SelectBranches from "../../components/shared/SelectBranches";
import SelectDepartments from "../../components/shared/SelectDepartments";
import SelectVendors from "../../components/shared/SelectVendors";
import stylesPo from "../../styles/purchaseOrders.module.css";

const RequisitionCreate = () => {
  const navigate = useNavigate();
  const { requisitionId } = useParams();
  const user = useStore((state) => state.user);
  const activeCompany = useStore((state) => state.activeCompany);
  const [values, setValues] = useState(null);
  const [taxes, setTaxes] = useState(null);
  const [allVendorsUncut, setAllVendorsUncut] = useState([]);
  const [tab, setTab] = useState('Notes');
  const [vendorCurrency, setVendorCurrency] = useState(null);
  const [isButtonsBlocked, setIsButtonsBlocked] = useState(false)
  const [updateAfterDeleteFile, setUpdateAfterDeleteFile] = useState(0);
  const [verify, setVerify] = useState(undefined)
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const requiredRef = useRef(false)
  const accessSubscription = useSubscriptionAllowed();

  const itemDefault = {
    id: null,
    requisitionId: null,
    description: '',
    generalLedgerId: '',
    taxId: '',
    quantity: 0,
    unitPrice: 0,
    subtotal: 0,
    taxTotal: 0,
    totalCost: 0,
    itemNotes: '',
  };

  const requisitionDefault = {
    requisitionState: '',
    title: '',
    budget: '',
    requester: user.firstName + ' ' + user.lastName,
    companyId: { label: activeCompany.name, value: activeCompany.id },
    branchId: '',
    departmentId: '',
    requestOnBehalfOf: '',
    vendorId: '',
    currency: '',
    approver: '',
    requisitionItemDTOS: [itemDefault],
    requisitionAttachments: [],
    deliveryDate: '',
    requestNotes: '',
    notes: [],
    vendorNote: ''
  };

  const {
    handleSubmit,
    register,
    control,
    setValue,
    getValues,
    trigger,
    setError,
    clearErrors,
    formState: { errors, isDirty, isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: requisitionDefault,
    values,
  });

  const { fields, append, remove } = useFieldArray({
    name: 'requisitionItemDTOS',
    control,
  });

  const formValues = useWatch({ name: 'requisitionItemDTOS', control });
  const companyFormValues = useWatch({ name: 'companyId', control });
  const currencyWatcher = useWatch({ control, name: 'currency' });
  const exchangeWatcher = useWatch({ control, name: 'exchange' });
  const budgetCurrencyWatcher = useWatch({ control, name: 'budget' });

  const onChangeVendor = async (e) => {
    const vendorCurrency = allVendorsUncut.find((item) => item?.vendor?.id === e.value)?.currency;
    setVendorCurrency(vendorCurrency);
    const budgetCompanyCurrency = budgetCurrencyWatcher?.currency || activeCompany?.defaultCurrency;
    const exchangeRate = await getExchangeRate(vendorCurrency, budgetCompanyCurrency, 1);
    setValue('exchange', exchangeRate);
    setValue('currency', vendorCurrency);
  };

  const subtotal = useMemo(() => {
    return formValues ? formValues.reduce((acc, current) => acc + (parseFloat(current.subtotal) || 0), 0) : 0;
  }, [formValues]);
  const taxTotal = useMemo(() => {
    return formValues ? formValues.reduce((acc, current) => acc + (parseFloat(current.taxTotal) || 0), 0) : 0;
  }, [formValues]);
  const totalCost = useMemo(() => {
    return formValues ? formValues.reduce((acc, current) => acc + (parseFloat(current.totalCost) || 0), 0) : 0;
  }, [formValues]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        spmsServiceService.getVendors(activeCompany?.id, {vendorStatus: "APPROVED", active: true}, 0,1000)
          .then((r) => {
            if (r.data.message === 'Operation Successful') {
              setAllVendorsUncut(r.data.data.content);
            }
          })
          .catch((err) => {
            console.log(err);
          }),
        spmsServiceService
          .getTaxList(activeCompany?.id)
          .then((r) => {
            const temp = r.data.data
              .filter((item) => item.active === true)
              .map((item) => ({
                value: { percentage: item.percentage, id: item.id },
                type: item.type,
              }));
            temp.splice(0, 0, {
              value: { id: null, percentage: 0 },
              type: 'No tax',
            });
            setTaxes(temp);
          })
          .catch((err) => {
            console.log(err);
          }),
      ]);
    };
    if (activeCompany !== null) {
      fetchData();
    }
  }, [activeCompany]);

  useEffect(() => {
    setValue('companyId', { label: activeCompany.name, value: activeCompany.id });
    if (companyFormValues?.value !== activeCompany?.id) {
      setValue('branchId', '');
      setValue('departmentId', '');
      setValue('vendorId', '');
      if (formValues?.length > 0) {
        for (let i = 0; i < formValues.length; i++) {
          setValue(`requisitionItemDTOS.${i}.generalLedgerId`, '');
        }
      }
    }
  }, [activeCompany]);

  useEffect(() => {
    const fetchRequisition = async () => {
      await spmsServiceService.getRequisition(requisitionId).then((r) => {
        if (r.data.message === 'Operation Successful') {
          console.log(r.data.data);
          const {
            requester,
            requisitionId,
            deliveryDate,
            department,
            branch,
            company,
            currency,
            requisitionItems,
            vendor,
            requestOnBehalfOf,
            budget,
            vendorNotes,
            ...other
          } = r.data.data;
          let editRequisitionItems;
          if (requisitionItems !== undefined) {
            if (requisitionItems.length > 0) {
              editRequisitionItems = requisitionItems.map((item) => ({
                ...item,
                generalLedgerId: {label: item.glAccount.glCode, value: item.glAccount.id, text: item.glAccount.description},
                taxId: taxes?.filter((el) => el.value.id === (item?.tax?.id || null))[0],
              }));
            }
          }
          const editValues = {
            ...other,
            companyId: { label: company.name, value: company.id },
            branchId: {label: branch.name, value: branch.id},
            departmentId: { label: department.name, value: department.id },
            requestOnBehalfOf: {label: requestOnBehalfOf.name, value: requestOnBehalfOf.email, userId: requestOnBehalfOf.userId},
            vendorId: {label: vendor.legalName, value: vendor.id},
            currency: currency?.code,
            exchange: currency?.rate,
            requisitionItemDTOS: editRequisitionItems,
            deliveryDate: deliveryDate !== undefined ? moment(deliveryDate, 'DD/MM/YYYY').toDate() : '',
            budget: {label: budget?.name, value: budget?.id, currency: budget?.currency},
            vendorNote: vendorNotes?.note
          };
          setValues(editValues);
          const vendorCurrency = allVendorsUncut.find(
            (item) => item?.vendor?.id === vendor.id,
          )?.currency;
          setVendorCurrency(vendorCurrency);
        }
      });
    };
    if (requisitionId !== undefined) {
      fetchRequisition();
    }
  }, [requisitionId, taxes, allVendorsUncut, updateAfterDeleteFile]);

  function getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = (_) => resolve(reader.result.split(';base64,')[1]);
      reader.onerror = (e) => reject(e);
    });
  }

  const verifyBudget = async (data) => {
    console.log(data);
    setToast((item) => ({...item, opened: false}));
    const {budget, requisitionItemDTOS, exchange} = data;
    const newPurchaseOrderItems = requisitionItemDTOS !== undefined && requisitionItemDTOS.length > 0 ? requisitionItemDTOS.map((item) => ({
      ...item,
      generalLedgerId: item.generalLedgerId.value,
      taxId: item.taxId.value.id,
    })) : [];
    const newData = {
      budget: {
        id: budget.value,
      },
      currency: {
        rate: exchange
      },
      purchaseOrderItems: newPurchaseOrderItems,
    };
    await spmsServiceService.verifyPurchaseOrderBudget(newData).then(r => {
      if (r.data.message === "Operation Successful"){
        setVerify(r.data.data)
        setToast({
          opened: true,
          message: "Budget verified successfully",
          type: 'success',
        });
      }
    }).catch(err => {
      console.log(err)
      setToast({
        opened: true,
        message: err.response.data.message !== undefined ? err.response.data.message : err.response.data.error,
        type: 'fail',
      });
    })
  }

  const onSubmit = async (data, formType) => {
    console.log(data);
    setIsButtonsBlocked(true)
    setToast((item) => ({ ...item, opened: false }));

    if (verify === undefined && formType === 'PENDING_APPROVAL' && accessSubscription?.plan?.budgetManagement){
      setIsButtonsBlocked(false)
      setToast({
        opened: true,
        message: 'Please verify budget',
        type: 'fail',
        cb: () => setToast((item) => ({ ...item, opened: false }))
      });
      return
    }

    const {
      exchange,
      budget,
      branchId,
      companyId,
      currency,
      departmentId,
      vendorId,
      deliveryDate,
      requisitionItemDTOS,
      requisitionAttachments,
      requisitionState,
      vendorNote,
      ...other
    } = data;
    requiredRef.current = false;
    const newRequisitionItemDTOS =
      requisitionItemDTOS !== undefined && requisitionItemDTOS.length > 0 ? requisitionItemDTOS.map((item) => ({
        ...item,
        generalLedgerId: item.generalLedgerId.value,
        taxId: item.taxId.value.id,
      })) : [];
    let newPurchaseOrderAttachments = [];
    if (requisitionAttachments !== undefined && requisitionAttachments.length > 0) {
      const files = requisitionAttachments.map(async (item) => ({
        name: item.name.split(' ').join('-'),
        document: await getBase64(item.attachment),
      }));
      newPurchaseOrderAttachments = await Promise.all(files).then((result) => result);
    }
    if (
      (!newPurchaseOrderAttachments || !newPurchaseOrderAttachments?.length) &&
      !values?.attachments.length &&
      formType === 'PENDING_APPROVAL'
    ) {
      setIsButtonsBlocked(false)
      setTab('Documents');
      requiredRef.current = true
      setError('requisitionAttachments', { type: 'focus', message: 'Files required' });
      return;
    }
    let roboUserData = '';
    if (data?.requestOnBehalfOf !== '' && data?.requestOnBehalfOf !== null) {
      roboUserData = {
        userId: data.requestOnBehalfOf.userId,
        email: data.requestOnBehalfOf.value,
        name: data.requestOnBehalfOf.label,
      };
    }
    const newData = {
      ...other,
      requisitionState: requisitionState !== undefined && requisitionState !== '' ? requisitionState : 'DRAFT',
      title: data.title,
      requester: {
        userId: user.id,
        email: user.email,
        name: user.firstName + ' ' + user.lastName,
      },
      companyId: companyId.value,
      branchId: branchId.value,
      budget: {
        id: budget.value,
      },
      departmentId: departmentId.value,
      requestOnBehalfOf: roboUserData,
      attachments: newPurchaseOrderAttachments,
      deliveryDate: moment(deliveryDate).format('DD/MM/YYYY'),
      currency: {
        code: data.currency,
        rate: exchange,
      },
      vendorId: vendorId.value,
      vendorNotes: {
        note: vendorNote === '' ? null : vendorNote
      },
      requisitionItems: newRequisitionItemDTOS,
    };
    if (requisitionId) {
      console.log('EditMode', newData);
      await spmsServiceService
        .updateRequisition(requisitionId, newData)
        .then((r) => {
          console.log(r);
          if (
            formType === 'PENDING_APPROVAL' &&
            newRequisitionItemDTOS.length > 0 &&
            requisitionId !== undefined
          ) {
            spmsServiceService
              .submitForApprovalRequisition(requisitionId)
              .then((r) => {
                setToast({
                  opened: true,
                  message: 'Requisition submitted for approval successfully',
                  type: 'success',
                  cb: () => navigate('/requisitions/overview/pending'),
                });
              })
              .catch((reason) => {
                setIsButtonsBlocked(false)
                setToast({
                  opened: true,
                  message:
                    reason.response.data.message !== undefined
                      ? reason.response.data.message
                      : reason.response.data.error,
                  type: 'fail',
                });
              });
          } else {
            setToast({
              opened: true,
              message: values?.requisitionState === "REJECTED" ? 'This requisition will now be available in draft' : 'Requisition updated successfully',
              type: 'success',
              cb: () => navigate('/requisitions/overview/drafts'),
            });
          }
        })
        .catch((reason) => {
          console.log(reason);
          setIsButtonsBlocked(false)
          setToast({
            opened: true,
            message:
              reason.response.data.message !== undefined
                ? reason.response.data.message
                : reason.response.data.error,
            type: 'fail',
          });
        });
    } else {
      console.log('newData', newData);
      await spmsServiceService
        .createRequisition(newData)
        .then((r) => {
          console.log(r);
          if (r.data.message === 'Operation Successful') {
            if (
              formType === 'PENDING_APPROVAL' &&
              newRequisitionItemDTOS.length > 0 &&
              r.data.data.id !== undefined
            ) {
              spmsServiceService
                .submitForApprovalRequisition(r.data.data.id)
                .then((r) => {
                  setToast({
                    opened: true,
                    message: 'Requisition submitted for approval successfully',
                    type: 'success',
                    cb: () => navigate('/requisitions/overview/pending'),
                  });
                })
                .catch((reason) => {
                  setIsButtonsBlocked(false)
                  setToast({
                    opened: true,
                    message:
                      reason.response.data.message !== undefined
                        ? reason.response.data.message
                        : reason.response.data.error,
                    type: 'fail',
                  });
                });
            } else {
              setToast({
                opened: true,
                message: 'Requisition created successfully',
                type: 'success',
                cb: () => navigate('/requisitions/overview/drafts'),
              });
            }
          }
        })
        .catch((reason) => {
          setIsButtonsBlocked(false)
          setToast({
            opened: true,
            message:
              reason.response.data.message !== undefined
                ? reason.response.data.message
                : reason.response.data.error,
            type: 'fail',
          });
        });
    }
  };

  const files = (files) =>
    files.map((file) => ({
      attachment: file,
      name: file.name,
    }));

  const deleteDocument = (key) => {
    spmsServiceService
      .deleteDocument('requisitions', requisitionId, key)
      .then(() => {
        setToast({
          opened: true,
          message: 'Document has been deleted',
          type: 'success',
          cb: () => setToast((item) => ({ ...item, opened: false })),
        });
        setUpdateAfterDeleteFile((state) => state + 1);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.response.data.message,
          type: 'fail',
          cb: () => setToast((item) => ({ ...item, opened: false })),
        });
      });
  };

  const basicVerifyBudget = (actionFunction) => (
    <Button.ActionLabeled onClick={() => actionFunction()}>
      <Button.Action
        $style="pink"
        $variant="circle"
        $width={20}
        $height={20}
        type="button"
      >
        <Icon $width={18} $height={18} $icon="plus" $color="white"/>
      </Button.Action>
      <Text weight={700} type="subtitle">
        Verify Budget
      </Text>
    </Button.ActionLabeled>
  )

  return (
    <>
      <Text weight={600} type="h2">{requisitionId !== undefined ? 'Edit' : 'Create'} Requisition</Text>
      <Box $mobExtend $asHolder $noOverflow>
        <form id="reqForm">
          <div className={generalStyles.fieldsThree}>
            <div className="inp-container">
              <Controller
                name="title"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Request Title is required',
                  },
                  maxLength: {
                    value: 50,
                    message: 'Maximum 50 characters',
                  },
                  validate: {
                    onlyAllowed: (v) => regExps.poRequisitionsTitle.test(v) || 'Alphabetic, numeric, &().- characters and space only',
                    onlyWhiteSpaces: (v) => !/^\s+$/.test(v) || 'You have only spaces',
                  },
                }}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="Enter Title"
                    className={errors.hasOwnProperty(field.name) && 'error'}
                    $label="Request Title"
                    $labelRequired
                    $tooltip="Descriptive title for requisition"
                    {...field}
                  />
                )}
              />
              {errors.title && <p className="error-message">{errors.title?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="requester"
                control={control}
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="corien@int"
                    $label="Requester"
                    disabled
                    readOnly
                    {...field}
                  />
                )}
              />
            </div>
            <div className="inp-container">
              <Label $title="Request on behalf of" $tooltipText="The user you are raising the requisition on behalf of"/>
              <Controller
                name="requestOnBehalfOf"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <SelectUsers
                    {...rest}
                    selectRef={ref}
                    className={errors.hasOwnProperty(rest.name) ? 'react-select-container error' : 'react-select-container'}
                    isClearable={true}
                    exclude={[user?.id]}
                  />
                )}
              />
            </div>
            <div className="inp-container">
              <Label $title="Budget" $tooltipText="Budget cost will be expensed against" $isRequired={accessSubscription?.plan?.budgetManagement}/>
              {!accessSubscription?.plan?.budgetManagement && (
                <div className={generalStyles.upgradeBtnInLabel}><UpgradePlan asLink text={`Budget available on Standard and Premium plans.`}/></div>
              )}
              <Controller
                name="budget"
                control={control}
                rules={{
                  required: {
                    value: accessSubscription?.plan?.budgetManagement,
                    message: 'Budget is required',
                  },
                }}
                render={({field: {ref, ...rest}}) => (
                  <SelectBudgets
                    {...rest}
                    selectRef={ref}
                    className={errors.hasOwnProperty(rest.name) ? 'react-select-container error' : 'react-select-container'}
                    isDisabled={!accessSubscription?.plan?.budgetManagement}
                  />
                )}
              />
            </div>
            <div className="inp-container">
              <Label $title="Branch" $tooltipText="Physical location items will be delivered to" $isRequired/>
              <Controller
                name="branchId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Branch is required',
                  },
                }}
                render={({field:{ref, ...rest}}) => (
                  <SelectBranches
                    {...rest}
                    selectRef={ref}
                    className={errors.hasOwnProperty(rest.name) ? 'react-select-container error' : 'react-select-container'}
                  />
                )}
              />
              {errors.branchId && <p className="error-message">{errors.branchId?.message}</p>}
            </div>
            <div className="inp-container">
              <Label $title="Department" $tooltipText="Department requesting the items" $isRequired/>
              <Controller
                name="departmentId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Department is required',
                  },
                }}
                render={({field: {ref, ...rest}}) => (
                  <SelectDepartments
                    {...rest}
                    selectRef={ref}
                    className={errors.hasOwnProperty(rest.name) ? 'react-select-container error' : 'react-select-container'}
                  />
                )}
              />
              {errors.departmentId && <p className="error-message">{errors.departmentId?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="deliveryDate"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Delivery Date is required',
                  },
                  maxLength: {
                    value: 10,
                    message: 'Maximum 10 characters',
                  },
                }}
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    placeholderText="Select Date"
                    selected={field.value}
                    wrapperClassName="custom-datepicker"
                    customInput={
                      <Input
                        {...field}
                        $label="Delivery Date"
                        $labelRequired
                        className={errors.hasOwnProperty(field.name) && 'error'}
                        $tooltip="Desired delivery date of items"
                      />
                    }
                    onChange={(e) => {
                      field.onChange(e);
                      trigger(field.name);
                    }}
                  />
                )}
              />
              {errors.deliveryDate && <p className="error-message">{errors.deliveryDate?.message}</p>}
            </div>
            <div className="inp-container">
              <Label $title="Vendor" $tooltipText="Preferred vendor to supply items" $isRequired/>
              <Controller
                name="vendorId"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: 'Vendor is required',
                  },
                }}
                render={({field: {ref, onChange, ...rest}}) => (
                  <SelectVendors
                    {...rest}
                    selectRef={ref}
                    onChange={(e) => {
                      onChange(e);
                      onChangeVendor(e);
                    }}
                    className={errors.hasOwnProperty(rest.name) ? 'react-select-container error' : 'react-select-container'}
                  />
                )}
              />
              {errors.vendorId && <p className="error-message">{errors.vendorId?.message}</p>}
            </div>
            <div className="inp-container">
              <Controller
                name="exchange"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input
                    type="text"
                    placeholder="0"
                    $label="Currency Rate of Exchange"
                    $tooltip="Vendor vs your budget currency"
                    // readOnly
                    {...field}
                  />
                )}
              />
              {vendorCurrency && (
                <Text
                  weight={500}
                  type="body-3"
                  style={{ position: 'absolute', top: '100%', left: 0, marginTop: 4 }}
                >
                  Vendor to Budget currency exchange ({vendorCurrency}:
                  {budgetCurrencyWatcher?.currency || activeCompany?.defaultCurrency})
                </Text>
              )}
            </div>
            <input type="hidden" {...register('currency')} />
          </div>

          <div className={styles.items}>
            <div className={styles.itemsTable}>
              <div className={styles.itemsTableHead}>
                <div/>
                <Text type="body-2" weight={500}>
                  Description
                </Text>
                <Text type="body-2" weight={500}>
                  GL Code
                </Text>
                <Text type="body-2" weight={500}>
                  Quantity
                </Text>
                <Text type="body-2" weight={500}>
                  Unit Price
                </Text>
                <Text type="body-2" weight={500}>
                  Tax
                </Text>
                <Text type="body-2" weight={500}>
                  Subtotal
                </Text>
              </div>
              <div className={styles.itemsTableBody}>
                {fields.map((field, index) => {
                  return (
                    <Item
                      key={field.id}
                      field={field}
                      index={index}
                      register={register}
                      errors={errors}
                      remove={remove}
                      append={append}
                      control={control}
                      setValue={setValue}
                      getValues={getValues}
                      taxes={taxes}
                      verifyBudget={verify}
                      budgetCur={budgetCurrencyWatcher?.currency}
                      budgetValue={budgetCurrencyWatcher?.budgetValue}
                      exchangeRate={exchangeWatcher !== undefined ? exchangeWatcher : 1}
                      name="requisitionItemDTOS"
                    />
                  );
                })}
              </div>
            </div>

            <div className={styles.itemsAdd}>
              <Button.ActionLabeled onClick={() => append(itemDefault)}>
                <Button.Action
                  $style="pink"
                  $variant="circle"
                  $width={20}
                  $height={20}
                  type="button"
                >
                  <Icon $width={18} $height={18} $icon="plus" $color="white" />
                </Button.Action>
                <Text weight={700} type="subtitle">
                  Add Line
                </Text>
              </Button.ActionLabeled>
              {!accessSubscription?.plan?.budgetManagement ? (
                <UpgradePlan
                  text={`Budget available on Standard and Premium plans.`}
                  triggerComponent={basicVerifyBudget}
                />
              ) : (
                <Button.ActionLabeled onClick={handleSubmit((data) => verifyBudget(data))}>
                  <Button.Action
                    $style="pink"
                    $variant="circle"
                    $width={20}
                    $height={20}
                    type="button"
                  >
                    <Icon $width={18} $height={18} $icon="plus" $color="white"/>
                  </Button.Action>
                  <Text weight={700} type="subtitle">
                    Verify Budget
                  </Text>
                </Button.ActionLabeled>
              )}
            </div>

            <div className={stylesPo.summaryWrapper}>
              <div className={stylesPo.vendorNotes}>
                <div className="inp-container">
                  <Controller
                    name="vendorNote"
                    control={control}
                    rules={{
                      pattern: {
                        value: regExps.notes,
                        message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £"
                      },
                    }}
                    render={({field}) => (
                      <Textarea
                        {...field}
                        $low
                        $counter
                        $counterMax={500}
                        placeholder="Vendor Notes text goes here"
                        className={errors.hasOwnProperty(field.name) && 'error'}
                      />
                    )}
                  />
                  {errors.vendorNote && <p className="error-message">{errors.vendorNote?.message}</p>}
                </div>
              </div>
              <Summary
                subtotal={subtotal}
                taxTotal={taxTotal}
                totalCost={totalCost}
                currency={currencyWatcher}
                currencyBudget={budgetCurrencyWatcher?.currency || activeCompany?.defaultCurrency}
                totalBudgetCost={exchangeWatcher !== undefined ? totalCost * exchangeWatcher : totalCost}
              />
            </div>

          </div>
          <TabbedNav selected={tab} className={generalStyles.tabs}>
            <span onClick={() => setTab('Notes')}>Notes</span>
            <span onClick={() => setTab('Documents')}>Documents</span>
            {requisitionId !== undefined && <span onClick={() => setTab('History')}>History</span>}
            {/*<span onClick={() => setTab('repeat')}>Schedule Recurring</span>*/}
          </TabbedNav>

          <div className={styles.attachmentsBlock}>
            {tab === 'Notes' && (
              <div className={styles.attachmentsBlockItem}>
                <div className="inp-container">
                  <Controller
                    name="requestNotes"
                    control={control}
                    rules={{
                      pattern: {
                        value: regExps.notes,
                        message: "Only alpha characters, numbers and - ! . , & : ( ) % + = / @ ' $ € £"
                      },
                    }}
                    render={({ field }) => (
                      <Textarea
                        {...field}
                        $low
                        $counter
                        $counterMax={500}
                        placeholder="Notes text goes here"
                        className={errors.hasOwnProperty(field.name) && 'error'}
                      />
                    )}
                  />
                  {errors.requestNotes && <p className="error-message">{errors.requestNotes?.message}</p>}
                </div>
                <NotesTab data={values?.notes} />
              </div>
            )}
            {tab === 'Documents' && (
              <div className={styles.attachmentsBlockItem}>
                {!!values?.attachments?.length && (
                  <QuotesTab data={values?.attachments} deleteDocument={deleteDocument} />
                )}
                <div>
                  <Label $title="Upload Supporting Documents" $isRequired/>
                  <Documents
                    control={control}
                    fieldName="requisitionAttachments"
                    filesSchema={files}
                    error={errors?.requisitionAttachments?.message || errors?.requisitionAttachments?.root?.message}
                    required={requiredRef.current}
                    clearErrors={clearErrors}
                  />
                </div>
              </div>
            )}
            {tab === 'History' && requisitionId !== undefined ? (
              <HistoryTab data={values?.history} />
            ) : null}
            {/*{tab === 'repeat' && (*/}
            {/*  <div className={styles.repeatFields}>*/}
            {/*    <div>*/}
            {/*      <Label>Repeat this transaction every</Label>*/}
            {/*      <div className={styles.repeatTwoInputs}>*/}
            {/*        <Input type="text" placeholder="1" />*/}
            {/*        <Select*/}
            {/*          className={'react-select-container'}*/}
            {/*          classNamePrefix="react-select"*/}
            {/*          isSearchable={false}*/}
            {/*          placeholder="Month(s)"*/}
            {/*          options={[*/}
            {/*            { name: 'Month1', id: 1 },*/}
            {/*            { name: 'Month2', id: 2 },*/}
            {/*          ]}*/}
            {/*          getOptionLabel={(option) => option.name}*/}
            {/*          getOptionValue={(option) => option.id}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*      <DatePicker*/}
            {/*        dateFormat="dd/MM/yyyy"*/}
            {/*        minDate={new Date()}*/}
            {/*        placeholderText="Select date"*/}
            {/*        wrapperClassName="custom-datepicker"*/}
            {/*        customInput={<Input $label="Bill Date" />}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*      <Label>Due Date</Label>*/}
            {/*      <div className={styles.repeatTwoInputs}>*/}
            {/*        <Input type="text" placeholder="1" />*/}
            {/*        <Select*/}
            {/*          className={'react-select-container'}*/}
            {/*          classNamePrefix="react-select"*/}
            {/*          isSearchable={false}*/}
            {/*          placeholder="Day after the bill date"*/}
            {/*          options={[*/}
            {/*            { name: 'Day after the bill date', id: 1 },*/}
            {/*            { name: 'Day before the bill date', id: 2 },*/}
            {/*          ]}*/}
            {/*          getOptionLabel={(option) => option.name}*/}
            {/*          getOptionValue={(option) => option.id}*/}
            {/*        />*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*    <div>*/}
            {/*      <DatePicker*/}
            {/*        dateFormat="dd/MM/yyyy"*/}
            {/*        minDate={new Date()}*/}
            {/*        placeholderText="Select date"*/}
            {/*        wrapperClassName="custom-datepicker"*/}
            {/*        customInput={<Input $label="End Date (Optional)" />}*/}
            {/*      />*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*)}*/}
          </div>
        </form>
      </Box>
      <div className={generalStyles.pageButtons}>
        <Button.Main
          $primary
          $style="pink"
          type="submit"
          form="reqForm"
          onClick={handleSubmit((data) => onSubmit(data, 'DRAFT'))}
          disabled={
            !isDirty ||
            !isValid ||
            isButtonsBlocked ||
            formValues === undefined ||
            formValues.length < 1
          }
        >
          {requisitionId !== undefined ? 'Save' : 'Save as draft'}
        </Button.Main>
        <Button.Main
          $primary
          $style="pink"
          type="submit"
          form="reqForm"
          onClick={handleSubmit((data) => onSubmit(data, 'PENDING_APPROVAL'))}
          disabled={
            (!isDirty && values?.requisitionState === "REJECTED") ||
            !isValid ||
            isButtonsBlocked ||
            formValues === undefined ||
            formValues.length < 1
          }
        >
          Submit for Approval
        </Button.Main>
        <Button.Main
          $primary
          $style="gray"
          type="button"
          onClick={() => {
            const path = values?.requisitionState === 'REJECTED' ? 'rejected' : 'drafts';
            navigate('/requisitions/overview/' + path);
          }}
        >
          Discard
        </Button.Main>
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} cb={toast.cb} />
      ) : null}
    </>
  );
};

export default RequisitionCreate;
