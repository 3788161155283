import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styles from '../../../../styles/companySetup.module.css';
import * as Button from '../../../UI/Forms/Button';
import Icon from '../../../UI/General/Icon';
import Text from '../../../UI/Typography/Text';
import TabbedNav from '../../../UI/General/TabbedNav';
import Input from '../../../UI/Forms/Input';
import Dropdown from '../../../UI/General/Dropdown';
import generalStyles from '../../../../styles/general.module.css';
import { Menu, MenuItem } from '../../../UI/General/Menu';
import spmsServiceService from '../../../../services/spmsService.service';
import { Link, useNavigate } from 'react-router-dom';
import requestsServiceService from '../../../../services/requestsService.service';
import Box from '../../../UI/General/Box';
import Toast from '../../../UI/General/Toast';
import xlsxParser from 'xlsx-parse-json';
import DataTableBaseRemote from '../../../UI/General/DataTableBaseRemote';
import { useStore } from '../../../../store/store';
import Export from '../../../shared/Export';
import AddButton from '../../../shared/AddButton';

const ExpandedComponent = ({ data }) => {
  return (
    <div className={styles.extendedComponent} key={data.id}>
      <div className={styles.extendedComponentRow}>
        <Text weight={600}>Branch</Text>
        {!data.branches.length
          ? 'All'
          : data.branches.map((branch) => <Text key={branch.id}>{branch.name}</Text>)}
      </div>
      <div className={styles.extendedComponentRow}>
        <Text weight={600}>Vendor</Text>
        {!data.vendors.length
          ? 'All'
          : data.vendors.map((vendor) => <Text key={vendor.id}>{vendor.name}</Text>)}
      </div>
      <div className={styles.extendedComponentRow}>
        <Text weight={600}>Department</Text>
        {!data.departments.length
          ? 'All'
          : data.departments.map((department) => (
              <Text key={department.id}>{department.name}</Text>
            ))}
      </div>
      <div className={styles.extendedComponentRow}>
        <Text weight={600}>User</Text>
        {!data.users.length
          ? 'All'
          : data.users.map((user) => <Text key={user.id}>{user.email}</Text>)}
      </div>
    </div>
  );
};

const GlSetupBody = ({ companyId, isSubsidiary, companyName }) => {
  const [allGlAmount, setAllGlAmount] = useState(null);
  const [tab, setTab] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [debouncedValue, setDebouncedValue] = useState(filterText);
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [toggledClearRows, setToggleClearRows] = useState(false);
  const navigate = useNavigate();
  const [allUsers, setAllUsers] = useState(null);
  const [allBranches, setAllBranches] = useState(null);
  const [allDepartments, setAllDepartments] = useState(null);
  const [allVendors, setAllVendors] = useState(null);
  const [trigger, setTrigger] = useState(false);
  const [toast, setToast] = useState({
    opened: false,
    message: undefined,
    type: undefined,
  });
  const setSteps = useStore((state) => state.setSteps);

  const tableRef = useRef(null);
  const hiddenFileInput = useRef(null);
  const preopenedRef = useRef(localStorage.getItem('preOpenedGL'));
  useEffect(() => {
    if (preopenedRef.current && tableRef.current) {
      tableRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [preopenedRef.current, tableRef.current]);

  const columns = useMemo(
    () => [
      {
        name: 'GL Code',
        sortable: true,
        selector: (row) => row.glCode,
        cell: (row) => (
          <Link className={styles.addLink} to={`/gl-setup/${companyId}/edit/${row.id}`}>
            <span data-content={'GL Code'} className={generalStyles.tableValue}>
              <span>{row.glCode}</span>
            </span>
          </Link>
        ),
      },
      {
        name: 'Description',
        sortable: true,
        selector: (row) => row.description,

        cell: (row) => (
          <span data-content={'Description'} className={generalStyles.tableValue}>
            <span>{row.description}</span>
          </span>
        ),
      },
      {
        name: 'Financial Type',
        selector: (row) => row.type,
        sortable: true,
        cell: (row) => (
          <span data-content={'Financial Type'} className={generalStyles.tableValue}>
            <span>{row.type}</span>
          </span>
        ),
      },
      {
        name: 'Branches',
        cell: (row) => (
          <span data-content={'Branches'} className={generalStyles.tableValue}>
            <span>
              {row.branches !== undefined && allBranches !== null && row.branches.length > 0 && allBranches.length !== row.branches.length ? 'Limited' : 'All'}
            </span>
          </span>
        ),
      },
      {
        name: 'Vendors',
        cell: (row) => (
          <span data-content={'Vendors'} className={generalStyles.tableValue}>
            <span>{row.vendors !== undefined && allVendors !== null && row.vendors.length > 0 && row.vendors.length !== allVendors.length ? 'Limited' : 'All'}</span>
          </span>
        ),
      },
      {
        name: 'Departments',
        cell: (row) => (
          <span data-content={'Department'} className={generalStyles.tableValue}>
            <span>
              {row.departments !== undefined && allDepartments !== null && row.departments.length > 0 && row.departments.length !== allDepartments.length ? 'Limited' : 'All'}
            </span>
          </span>
        ),
      },
      {
        name: 'Users',
        cell: (row) => {
          console.log(row.users)
          console.log(allUsers)
          return (
            <span data-content={'Users'} className={generalStyles.tableValue}>
            <span>
              {row.users !== undefined && allUsers !== null && row.users.length > 0 && row.users.length !== allUsers.length
                ? 'Limited'
                : 'All'}
            </span>
          </span>
          )
        },
      },
      {
        name: 'Action',
        allowOverflow: true,
        button: true,
        cell: (row) => (
          <div className={generalStyles.actionMenuHolder}>
            <Dropdown collapsible className={generalStyles.actionMenu}>
              <Dropdown.Header>
                <Button.Action $style="white" $width={32} $height={32}>
                  <Icon $icon="menu-dots" $width={32} $height={32} $color="black" />
                </Button.Action>
              </Dropdown.Header>
              <Dropdown.Body>
                <Menu className={generalStyles.actionMenuList}>
                  {tab === true ? (
                    <>
                      {allGlAmount !== 1 && (
                        <MenuItem onClick={() => archiveGl([row.id])}>Archive</MenuItem>
                      )}
                      <MenuItem onClick={() => navigate(`/gl-setup/${companyId}/edit/${row.id}`)}>
                        Edit
                      </MenuItem>
                    </>
                  ) : (
                    <MenuItem onClick={() => activateGl([row.id])}>Activate</MenuItem>
                  )}
                </Menu>
              </Dropdown.Body>
            </Dropdown>
          </div>
        ),
      },
    ],
    [tab, allUsers, allBranches, allDepartments, allVendors],
  );

  const archiveGl = (id) => {
    setToast((item) => ({ ...item, opened: false }));
    spmsServiceService
      .deleteGlAccount(id)
      .then((r) => {
        setToast({
          opened: true,
          message: 'Archived',
          type: 'success',
        });
        setTrigger((trigger) => !trigger);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.message,
          type: 'fail',
        });
      });
  };

  const activateGl = (id) => {
    spmsServiceService
      .activateGlAccount(id)
      .then((r) => {
        setToast({
          opened: true,
          message: 'Activated',
          type: 'success',
        });
        setTrigger((trigger) => !trigger);
      })
      .catch((err) => {
        setToast({
          opened: true,
          message: err.message,
          type: 'fail',
        });
      });
  };

  const getData = useCallback(
    async (page, size) => {
      const search = {
        active: tab,
        glCode: debouncedValue,
      };
      const response = await spmsServiceService.getFilteredGlAccounts(
        companyId,
        page,
        size,
        search,
      );
      if (!allGlAmount) setAllGlAmount(response.data.data.totalElements);
      return response;
    },
    [companyId, tab, debouncedValue, trigger],
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(filterText);
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filterText]);

  useEffect(() => {
    let ignore = false;
    if (!ignore) {
      getData(0, 10);
    }
    return () => {
      ignore = true;
    };
  }, [getData]);

  const handleTabClick = (value) => {
    setTab(value);
    setResetPaginationToggle(!resetPaginationToggle);
    setToggleClearRows(!toggledClearRows);
  };

  const filterCmp = useMemo(
    () => (
      <Input
        type="text"
        $iconName="search"
        $iconColor="#ACAEC9"
        placeholder="Search by GL Code"
        value={filterText}
        onChange={(e) => setFilterText(e.target.value)}
      />
    ),
    [filterText],
  );

  useEffect(() => {
    const fetchBranchesDepartmentsAndUsers = async () => {
      await Promise.all([
        spmsServiceService.getBranches(companyId).then((r) => {
          if (r.data.status === 'success') {
            setAllBranches(r.data.data);
          }
        }),
        spmsServiceService.getDepartments(companyId).then((r) => {
          if (r.data.status === 'success' && r.data.data) {
            setAllDepartments(r.data.data);
          }
        }),
        spmsServiceService.getVendors(companyId,{vendorName: '', vendorStatus: "APPROVED", active: true}, 0, 100, ).then((r) => {
          console.log(r)
          if (r.data.status === 'success' && r.data.data) {
            setAllVendors(r.data.data);
          }
        }),
        requestsServiceService.getData(100, 0, {
          status: 'ACTIVE',
          companyId: companyId
        }).then((r) => {
          setAllUsers(r.data.data.content);
        }),
      ]);
    };

    if (companyId !== undefined) {
      fetchBranchesDepartmentsAndUsers();
    }
  }, [companyId]);

  const getTemplate = () => {
    spmsServiceService
      .getAttachmentsByKey('poms-upload-templates/POMS-GL-Import-Template.xlsx')
      .then((res) => {
        const path = res.data.data;
        const link = document.createElement('a');
        link.href = path;
        link.download = path.toString().split('/').pop();
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      });
  };

  const uploadImportedFile = async (file) => {
    setToast((item) => ({ ...item, opened: false }));
    try {
      const data = await xlsxParser.onFileSelection(file);
      const firstSheet = data.Sheet1;
      const json = JSON.stringify(firstSheet);
      await spmsServiceService.uploadGlBulk(companyId, json);
      getData(0, 10);
      setTrigger((trigger) => !trigger);
      setSteps();
      setToast({
        opened: true,
        message: 'The Gl was imported',
        type: 'success',
      });
    } catch (err) {
      setToast({
        opened: true,
        message: err.response.data.message,
        type: 'fail',
      });
    } finally {
      hiddenFileInput.current.value = null;
    }
  };

  const [toExport, setToExport] = useState([]);
  useEffect(() => {
    const getExp = async () => {
      const response = await spmsServiceService.getFilteredGlAccounts(companyId, 0, allGlAmount, {
        glCode: debouncedValue,
      });
      console.log(response.data.data.content)
      const csvData = response.data.data.content.map((item) => ({
        'GL Code': item.glCode,
        'Description': item.description,
        'Financial Type': item.type,
        'Branches': item.branches !== undefined && allBranches !== null && item.branches.length > 0 && item.branches.length !== allBranches.length ? 'Limited' : 'All',
        'Vendors': item.vendors !== undefined && allVendors !== null && item.vendors.length > 0 && item.vendors.length !== allVendors.length ? 'Limited' : 'All',
        'Departments': item.departments !== undefined && allDepartments !== null && item.departments.length > 0 && item.departments.length !== allDepartments.length ? 'Limited' : 'All',
        'Users': item.users !== undefined && allUsers !== null && item.users.length > 0 && item.users.length !== allUsers.length ? 'Limited' : 'All',
        'Status': item.active !== undefined && item.active !== false ? 'Active' : 'Archived',
      }));
      setToExport(csvData);
    };
    if (allGlAmount === null) {
      console.log('no results for export');
    } else {
      getExp();
    }
  }, [companyId, debouncedValue, trigger, allGlAmount, allBranches, allVendors, allDepartments, allUsers]);

  return (
    <Box $mobExtend $asHolder $radius={12} $noOverflow>
      <div ref={tableRef} className={styles.departmentBox}>
        <div className={generalStyles.tabSection}>
          <TabbedNav>
            <span onClick={() => handleTabClick(true)}>Active</span>
            <span onClick={() => handleTabClick(false)}>Archive</span>
          </TabbedNav>
          <div className={generalStyles.addItemButton}>
            <AddButton
              $click={() =>
                navigate(`/gl-setup/${companyId}/add`, {
                  state: {
                    isSubsidiary,
                    companyName,
                    companyHasGLs: !!allGlAmount,
                  },
                })
              }
            />
            <Export data={toExport} name="general-ledgers" />
            <div className={styles.importButton}>
              <Dropdown collapsible className={generalStyles.actionMenu}>
                <Dropdown.Header>
                  <Button.ActionLabeled>
                    <Button.Action
                      $style="pink"
                      $variant="circle"
                      $width={20}
                      $height={20}
                      type="button"
                    >
                      <Icon $width={20} $height={20} $icon="add" $color="white" />
                    </Button.Action>
                    <Text weight={600} type="subtitle">
                      Import
                    </Text>
                  </Button.ActionLabeled>
                </Dropdown.Header>
                <Dropdown.Body>
                  <Menu className={styles.actionMenuList}>
                    <MenuItem onClick={getTemplate}>Download the Template</MenuItem>
                    <MenuItem onClick={() => hiddenFileInput.current.click()}>
                      <span>
                        Upload GL
                        <input
                          hidden
                          ref={hiddenFileInput}
                          className={styles.importInput}
                          type="file"
                          onChange={(e) => {
                            uploadImportedFile(e.target.files[0]);
                          }}
                        />
                      </span>
                    </MenuItem>
                  </Menu>
                </Dropdown.Body>
              </Dropdown>
            </div>
          </div>
        </div>
        <div className={generalStyles.search}>{filterCmp}</div>
        <DataTableBaseRemote
          columns={columns || []}
          selectableRows={false}
          expandableRows
          expandableRowsComponent={ExpandedComponent}
          expandOnRowClicked
          paginationResetDefaultPage={resetPaginationToggle}
          fetchData={getData}
        />
      </div>
      {toast.opened === true ? (
        <Toast message={toast.message} opened={toast.opened} type={toast.type} />
      ) : null}
    </Box>
  );
};

export default GlSetupBody;
