import React from 'react';
import styled from "styled-components";
import Text from "../Typography/Text";

const NoData = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  min-height: 100px;
  padding: 16px;
  text-align:center;
  border: ${({ $border }) => ($border ? '1px solid #d9d9d9' : 'none')};
  @media all and (min-width: 768px){
    min-height: 200px;
    padding: 32px;
  }
`

const TableNoData = (props) => {
  return (
    <NoData $border={props.$bordered}>
      {props?.cmp ? props?.cmp : (
        <Text weight={500} type="h3">
          No data to display.
        </Text>
      )}
    </NoData>
  );
};

export default TableNoData;
